<script lang="ts" setup>
defineProps<{
	gradient?: boolean;
	clearSectors?: boolean;
}>();

const prepareImgUrl = useImage();
const imgBorder = `url(${prepareImgUrl(`nuxt-img/wheel-of-wins/wheel/border-lights-1.png`, {
	format: "webp",
	quality: "100"
})})`;
const imgBorder2 = `url(${prepareImgUrl(`nuxt-img/wheel-of-wins/wheel/border-lights-2.png`, {
	format: "webp",
	quality: "100"
})})`;
const imgArrowLight = `url(${prepareImgUrl(`nuxt-img/wheel-of-wins/wheel/arrow-light.png`, {
	format: "webp",
	quality: "100"
})})`;
const imgButtonLight = `url(${prepareImgUrl(`nuxt-img/wheel-of-wins/wheel/button-light.png`, {
	format: "webp",
	quality: "100"
})})`;
</script>
<template>
	<div :class="['fake-wheel', { 'shadow-gradient': gradient }]">
		<div class="percents">
			<AAnimationDrum antiClockWise>
				<NuxtImg
					:src="`nuxt-img/wheel-of-wins/wheel/sectors-percents${clearSectors ? '-clear' : ''}.png`"
					loading="lazy"
					quality="100"
					format="webp"
					alt="sectors percent"
				/>
			</AAnimationDrum>
		</div>
		<div class="coins">
			<AAnimationDrum>
				<NuxtImg
					:src="`nuxt-img/wheel-of-wins/wheel/sectors-coins${clearSectors ? '-clear' : ''}.png`"
					loading="lazy"
					quality="100"
					format="webp"
					alt="sectors coins"
				/>
			</AAnimationDrum>
		</div>
		<div v-if="gradient" class="shadow-gradient" />
	</div>
</template>

<style lang="scss" scoped>
.fake-wheel {
	position: relative;
	z-index: 1;
	background: v-bind(imgBorder) no-repeat center center / contain;
	animation: border-lights 1000ms linear infinite;
	& > div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	img {
		width: 100%;
		height: 100%;
	}
	&::before {
		content: "";
		z-index: 3;
		position: absolute;
		top: -4.4%;
		left: 50%;
		transform: translateX(-50%);
		width: 50%;
		height: 57%;
		background: v-bind(imgArrowLight) no-repeat center center / contain;
	}
	.percents {
		z-index: 2;
		width: 116%;
		height: 116%;
	}
	.coins {
		z-index: 4;
		width: 108%;
		height: 108%;
	}
	&::after {
		content: "";
		z-index: 5;
		position: absolute;
		top: 38%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 43%;
		height: 43%;
		background: v-bind(imgButtonLight) no-repeat center center / contain;
	}
	.shadow-gradient {
		z-index: 10;
		bottom: 0;
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		border-radius: 50%;
		background: linear-gradient(
			0deg,
			var(--celle) 43.24%,
			rgba(var(--celle-rgb), 0.91) 48.76%,
			rgba(var(--celle-rgb), 0.75) 52.21%,
			rgba(var(--celle-rgb), 0.61) 54.71%,
			rgba(var(--celle-rgb), 0.51) 56.28%,
			transparent 60.94%
		);
	}
}
@keyframes border-lights {
	50% {
		background: v-bind(imgBorder2) no-repeat center center / contain;
	}
}
</style>
